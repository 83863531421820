// import logo from './logo.svg';
import { ReactComponent as Virusicon} from './media/coronavirus.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Velkommen til janx.dk!
        </p>
        <Virusicon className="Virusicon" />
        <a
          className="App-link"
          href="https://www.dr.dk/nyheder/indland/status-paa-coronavirus-lige-nu"
          target="_blank"
          rel="noopener noreferrer"
        >
          Coronavirus status i Danmark lige nu
        </a>
      </header>
    </div>
  );
}

export default App;
